import React, { useEffect, useState } from 'react'
import '../styles/board.css'
import Button from 'react-bootstrap/Button';
// import { useNavigate } from 'react-router-dom';
import { useData } from '../backend/firebase';
import {isOver} from '../utils/logic'
import Modal from 'react-bootstrap/Modal';
import { Match } from '../components/match';
import { unRankBoard } from "../utils/logic";
import silhouette from '../assets/icons/silhouette.jpg'
// import wood from '../assets/backgrounds/brown-dark-wood.jpg'
import { getMoveRandom } from '../algorithms/randomPlayer';

const Play = ({setNav}) => {
  const [boardWidth, setWidth] = useState(Math.min(window.innerWidth,window.innerHeight*7/8)*.80)
  const [offset, setOffset] = useState(getOffset())
  const { curBoard, setCurBoard, myAccount, passAndPlayState, setPassAndPlayState, aiAgent } = useData()
  const [boardId, setmutBoardId] = useState(curBoard)
  const [boardIdUpdatedCallback, setBoardIdUpdatedCallback] = useState()
  // const navigate = useNavigate()
  const [wasReset, setWasReset] = useState(false)
  const [winner, setWinner] = useState(0)

  let { updateState, curBoardState, setCallback, archiveMatch} = useData()
  const [state, setState] = useState([
    [0, 1, 1, 3, 1, 1, 0],
    [0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0],
    [0, 2, 2, 4, 2, 2, 0],
  ])

  // const [TTwin, setTTwin] = useState(new Set([]))
  // const [TTloss, setTTloss] = useState(new Set([]))
  // const [TTunknown, setTTunknown] = useState({})

  const [turn, setTurn] = useState(1)

  const [currentlySelected, setCurrentlySelected] = useState(undefined)

  const [boardOrientation, setOrientation] = useState('white')
  const [myTurn, setMyTurn] = useState(1)


  // console.log("BOARD ID ", boardId)

  const handleResize = e => {
    setWidth(Math.min(window.innerWidth,window.innerHeight*7/8)*.80)
    setOffset(getOffset())
  };

  function getOffset(){
    return {x: window.innerWidth < window.innerHeight ? window.innerWidth *0.2 : 0, y: window.innerHeight < window.innerWidth ? window.innerHeight * 0.2 *7/8 : 0}
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    setCurBoard(localStorage.getItem("boardId"))
    setmutBoardId(localStorage.getItem("boardId"))
    console.log("stored board id: ", localStorage.getItem("boardId"))
    return () => {
      window.removeEventListener("resize", handleResize);
    }
  }, []);

  setTimeout(function () {
    if(!boardIdUpdatedCallback || wasReset) return

    requestAnimationFrame(() => boardIdUpdatedCallback(boardId))
    console.log("callback set from parent " + boardId)
    setWasReset(true);
  })

  function onTurnEnd(newState, dontPlay=undefined)
  {
    setState(newState)
    let over = isOver(newState)
    // runAI()
    if(boardId !== "") updateState(newState,3-turn,boardId,over)
    else setPassAndPlayState(
      {
        turn: 3-turn,
        state: newState
      }
    )

    if(dontPlay !== undefined) setTurn(3-dontPlay)
    else setTurn(3-turn)

    setWinner(over)

    //its always cpu, introduce a variable that determines whether cpu or pass and play
    

    
  }

  if(boardId === "" && turn === 2 && aiAgent !== "")
  {
    playMoveCpu(aiAgent, state, turn)
  }

  function playMoveCpu(agentName, newState, newTurn){
    if(agentName==="Random"){
      let move = getMoveRandom(newState, newTurn)
      const copy = [...newState]
      const temp = copy[move.y1][move.x1]
      copy[move.y1][move.x1] = copy[move.y2][move.x2]
      copy[move.y2][move.x2] = temp
      onTurnEnd(copy, newTurn)
    }
  }


  // function runAI()
  // {
  //   solve(state, 10, 3-turn, TTwin, TTloss, TTunknown)
  //   setTTwin(TTwin)
  //   setTTloss(TTloss)
  //   setTTunknown(TTunknown)
  // }


  const onBoardUpdated = (board)=>{
    console.log("callback " + board)
    syncBoard(board)
    return "BLA"
  }

  // const delay = ms => new Promise(res => setTimeout(res, ms));

  async function syncBoard(board)
  {
    if(board === undefined) return;

    // console.log("SYNC")
    // console.log(board["state"])
    
    // delay(1); //todox is this really supposed to exist?  delays are bad
    
    if(board["mode"] === 1)
      setState(unRankBoard(board["state"]))
    else
      { let copy = [...state]; for(let i = 0; i < 8; i++) copy[i] = board["state"][i+""]; setState(copy) }
    
    setTurn(board["turn"])
    setOrientation(board["isWhite"] ? 'white' : 'black')
    setMyTurn(board["isWhite"] ? 1 : 2)
    setWinner(isOver(unRankBoard(board["state"])))
  }

  useEffect(() => {
    console.log("use effect : " + boardId + " :")
    setupCallback(boardId)
    if(setBoardIdUpdatedCallback) setBoardIdUpdatedCallback(() => (boardIDDD) => setupCallback(boardIDDD))
    return ()=>{ setCallback(undefined);  console.log("callback cleared Board")} 
  }, []);

  function setupCallback(newBoardId){
    setmutBoardId(newBoardId)
    if(newBoardId !== "") 
    { 
      console.log("callback updated Board")
      setCallback(() => (board) => onBoardUpdated(board))
      onBoardUpdated(curBoardState);
    }
    else
    {
      if(passAndPlayState !== undefined)
      {
        setTurn(passAndPlayState["turn"])
        setState(passAndPlayState["state"])
      }
    }
  }


  return (
    <div className="Play" style={{backgroundColor:"#0C0A1A"}}>
    <Modal as={Modal.Dialog} centered show={winner !== 0}>
          <div style={{background: "#111", bordercolor: "grey", borderWidth: 1, borderStyle: "solid", borderRadius: 6}}>
          <Modal.Body>
          <h3 className="mt-4 Auth-form-title">{winner === 1 ? "Black" : "White"} Wins!</h3>
          <div  className="center-screen-noresize">
            {/* <Button className="mt-2 mx-4 mb-4" variant="primary" onClick={()=> { setCurBoard(""); navigate(SiteRoutes.Home.path)}}>
              Replay
            </Button> */}
            <Button className="mt-2 mx-4 mb-4" variant="secondary" onClick={()=> {if(boardId!==""){archiveMatch(boardId)} else{setPassAndPlayState(undefined)}; localStorage.setItem("boardId", ""); setCurBoard(""); setNav(0)}}>
              Exit
            </Button>
          </div>
          </Modal.Body>
          {/* <Modal.Footer>
          </Modal.Footer> */}
          </div>
        </Modal>
    <div  className="center-screen" style={{display: "flex"}}>
      {
        boardId === ""?
        <></>:
        <div className="UserTag mb-4" onClick={()=>{ alert("profile card (modal?)")}} style={{height:45, width: (boardWidth*7/8)*4/4 + 30, float: "left", marginTop: 0, border: turn !== myTurn ? "solid #777" : "", borderWidth: "1.5px"}}>
          <div id="icon" style={{flex: "12%"}}><img src={silhouette} style={{width: 25, height: 25, borderRadius: 15}}/></div> <div id="title" style={{flex: "88%", display: "flex", float: "left", textAlign: "center"}}>{boardId.split("|")[boardId.split("|")[0] === myAccount.username ? 1 : 0]}</div>
      </div>}
      <div style={{  width: "fit-content", height: "fit-content", padding: 15, borderRadius: 10, backgroundColor: "#0C1A2A"}}>
      <Match boardWidth={boardWidth} state={state} setState={onTurnEnd} boardOrientation={boardOrientation} turn={turn === myTurn || boardId === "" ? turn : -1} currentlySelected={currentlySelected} setCurrentlySelected={setCurrentlySelected}/>
      </div>
      {
        boardId === ""?
        <></>:
        <div className="UserTag mt-4" onClick={()=>{ alert("profile card (modal?)")}} style={{height:45, width: (boardWidth*7/8)*4/4 + 30, float: "left", marginTop: 0, border: turn === myTurn ? "solid #777" : "", borderWidth: "1.5px", color:"white"}}>
          <div id="icon" style={{flex: "12%"}}><img src={silhouette} style={{width: 25, height: 25, borderRadius: 15}}/></div> <div id="title" style={{flex: "88%", display: "flex", float: "left", textAlign: "center"}}>{myAccount?.username ?? "Player"}</div>
      </div>}
    </div>
    </div>
  )
}

export default Play

import React, { useState } from 'react';
import { Route, Switch, Redirect, Routes } from "react-router-dom";
// import Footer from '../components/Footer';
// import NavigationBar from '../components/NavigationBar';
import { DataProvider, useData } from './backend/firebase';
import { SiteRoutes } from "./routes";
import Home from './pages/home';
import Play from './pages/play';
import Login from './pages/login';
import History from './pages/History';
import Settings from './pages/Settings';
import Friends from './pages/Friends';

import './styles/board.css'
import './App.css'
import SideBar from './components/sidebar';
import { ReactComponent as Apple } from './assets/icons/apple.svg';
import AccountCreation from './pages/accountCreation';
import LoadingScreen from './pages/LoadingScreen';

export default () => {
  
  let {user, myAccount, curBoard} = useData()
  const [nav, setNav] = useState(0)

  console.log(user)

  const tabs = [
    <Home setNav={setNav}/>,
    curBoard === undefined ? <Home setNav={setNav}/> : <Play setNav={setNav}/>,
    <Friends/>,
    <History/>,
    <Settings/>,
  ]

  return(
  <div className='App'>
      { user === null || user===undefined ? 
      ( user === undefined ? <LoadingScreen/> : <Login/> )
      :
        myAccount === null || myAccount===undefined ?
      ( myAccount === undefined ? <LoadingScreen/> : <AccountCreation/> )
        :
    <>
      <SideBar nav={nav} setNav={setNav}/>
      {tabs[nav]}
      {/* <Routes>
        <Route path={SiteRoutes.Home.path} element={<Home/>} />
        <Route path={SiteRoutes.Play.path} element={<Play/>} />
      </Routes> */}
    </>}
    </div>
  )
}

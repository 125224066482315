import React, { useEffect, useState } from 'react'
import { useData } from "../backend/firebase";
import '../styles/board.css'

import Button from 'react-bootstrap/Button';
import { Link, useNavigate } from 'react-router-dom';
import { SiteRoutes } from '../routes';
import Login from './login';
import AccountCreation from './accountCreation';
import Modal from 'react-bootstrap/Modal';

import {Match} from "../components/match"

import { ReactComponent as BoardSvg } from '../assets/icons/nav/board2.svg';
import { ReactComponent as Computer } from '../assets/icons/nav/computer.svg';
import { ReactComponent as Friend } from '../assets/icons/nav/person.svg';
import isaac from "../assets/icons/isaac.png";
import { CarouselMatch } from '../components/CarouselMatch';
import { unRankBoard } from '../utils/logic';

const Home = ({setNav}) => {
  let {user, myAccount, logout, createMatch, myMatches, setCurBoard, loadCurBoard, getMyUsername, setCallback, setCurBoardState, archiveMatch, setPassAndPlayState, setAiAgent} = useData()
  const [createModalShown, setCreateModalShown] = useState(false)
  const [isCreating, setIsCreating] = useState(false)
  const [friendId, setFriendId] = useState("")
  const [matches, setMatches] = useState({})
  const navigate = useNavigate();
  const [deletionMatch, setDeletionMatch] = useState("")

  // console.log("converted string is: '" + String.fromCharCode(0b00000011)+"'")

  const myUsername = getMyUsername();

  useEffect((()=>{
    setCallback(() => () => setMatches(myMatches))
    setMatches(myMatches)
    console.log("callback updated Home")
    // localStorage.setItem("boardId", "")
      
      return ()=>{ setCallback(undefined);  console.log("callback cleared Home")} 
  }), [])

  // async function printYahoo(){
  //   console.log("Yahoo")
  // }

  async function challengeFriend(friendId)
  {
    setIsCreating(true)

    const res = await createMatch(friendId)

    if(res !== undefined) { localStorage.setItem("boardId", res); setNav(1) }

    setIsCreating(false)
  }

  async function joinMatch(match, matchId)
  {
    console.log("setting match id " + match)
    setCurBoard(matchId)
    setCurBoardState(match)
    localStorage.setItem("boardId", matchId)
    // loadCurBoard(matchId)
    // navigate(SiteRoutes.Play.path);
    setNav(1)
  }

  function startLocalMatch()
  {
    setCurBoard("");
    setPassAndPlayState(undefined);
    localStorage.setItem("boardId", "");
    setNav(1)
  }
  // const state = [
  //   [0, 1, 1, 3, 1, 1, 0],
  //   [0, 0, 0, 0, 0, 0, 0],
  //   [0, 0, 0, 0, 0, 0, 0],
  //   [0, 0, 0, 0, 0, 0, 0],
  //   [0, 0, 0, 0, 0, 0, 0],
  //   [0, 0, 0, 0, 0, 0, 0],
  //   [0, 0, 0, 0, 0, 0, 0],
  //   [0, 2, 2, 4, 2, 2, 0],
  // ]

console.log(myMatches)

  return (
    <>
        <div className="ModalShade" hidden={deletionMatch === ""} onClick={()=>setDeletionMatch("")}>
          <div className="ModalBox">
            <h3 className="ModalTitle">Delete match?</h3>
            <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", width: "fit-content", marginInline: "auto"}}>
              <div className="ModalButton" onClick={()=>setDeletionMatch("")}>
                No
              </div>
              <div className="ModalButton" id="danger" style={{marginLeft: 30}} onClick={()=>{archiveMatch(deletionMatch); setDeletionMatch("")}}>
                Yes
              </div>
            </div>
          </div>
        </div>

        <Modal as={Modal.Dialog} centered show={createModalShown}>
          <div style={{background: "#111", bordercolor: "grey", borderWidth: 1, borderStyle: "solid", borderRadius: 6}}>
          <Modal.Body>
          <h3 className="Auth-form-title">Challenge a Friend</h3>
            <div className="form-group mt-4" style={{color:"white"}}>

            {/* {
              myAccount["friends"]?.map(x=>(<>
              {x}
              <button onClick={()=>setFriendId(x)} style={{width: "max-content", height: "max-content"}}>
                  +
                </button>
              <br/>
              </>))
            } */}

              <input
                type="text"
                className="form-control mt-4"
                placeholder="Type friend's username"
                value={friendId}
                list="friends"
                required
                onChange={(e)=>setFriendId(e.target.value)}
              />
              <datalist id="friends">
              {
                myAccount["friends"]?.map(x => (
                  <option value={x} />
                ))
              }
              </datalist>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" className="text-gray ms-auto" onClick={()=>setCreateModalShown(false)} style={{marginRight:"20px"}} disabled={isCreating}>
              Cancel
            </Button>
            <Button variant="primary" onClick={()=>challengeFriend(friendId)} disabled={isCreating||(!myAccount["friends"]?.includes(friendId))}>
              Challenge
            </Button>
          </Modal.Footer>
          </div>
        </Modal>
    <div style={{width: "100%",height: "100%", display: "flex", backgroundColor: "#0C0A1A", flexDirection: "row", justifyContent:"center", alignItems: "center"}}>
      <div style={{height: "100%", maxWidth: "30%",margin: 0, display: "flex", flex: "30%", flexDirection: "column",  justifyContent:"center", alignItems: "center"}}>
        <div className="HomeButton" onClick={()=>{ setAiAgent(""); startLocalMatch()}} style={{height:60, color: "white", }}>
            <div id="icon"><BoardSvg style={{width: 30, height: 30, fill:"white"}}/></div> <div id="title">Pass and Play</div>
        </div>
        <div className="HomeButton" onClick={()=>{ setAiAgent("Random"); startLocalMatch()}} style={{height:60, color: "white", marginTop: 40 }}>
            <div id="icon"><Computer style={{width: 30, height: 30, fill:"white"}}/></div> <div id="title">Play vs Computer</div>
        </div>
        <div className="HomeButton" onClick={()=>{ setCreateModalShown(true)}} style={{height:60, color: "white", marginTop: 40 }}>
            <div id="icon"><Friend style={{width: 30, height: 30, fill:"white"}}/></div> <div id="title">Challenge a Friend</div>
        </div>
      </div>
      {/* <div  className="center-screen">
      <Match boardOrientation={"black"} boardWidth={100} state={state} turn={-1}/>

      </div> */}
      <div style={{height: "80%",  margin: 0, flex: "70%",  flexWrap: "wrap", maxWidth: "70%", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", overflow: "auto"}}>
        {
          Object.keys(myMatches).map((matchId) => {
            return (
              <CarouselMatch boardOrientation={myMatches[matchId]["isWhite"] ? "white" : "black"} isMyTurn={(myMatches[matchId]["isWhite"] ? 1 : 2) === myMatches[matchId]["turn"] ? true : false} onClick={()=>joinMatch(myMatches[matchId], matchId)} onDelete={()=>setDeletionMatch(matchId)} state={myMatches[matchId]["mode"] === undefined ? [...Object.keys(myMatches[matchId]["state"]).map((row) => {console.log(row); return myMatches[matchId]["state"][row]})] : unRankBoard(myMatches[matchId]["state"])} avatar={myMatches[matchId]["avatar"]} playerName={`${matchId.replace(myUsername, "").replace(matchId.split('|')[2], "").replaceAll("|","")}`}/>
            )
          })
        }
      </div>
    </div>
    </>
  )
}

export default Home


{/* 
<div className='Auth-form-container'>
        <div className="Auth-form align-items-center justify-content-center">
        <p className='Auth-form-title'>Welcome {myAccount?.username ?? ""}</p>
          Play
        <Button as={Link} style={{width: "70%", marginLeft: "15%", marginBottom: 30}} to={SiteRoutes.Play.path}>
          Pass and Play
        </Button>
        <Button onClick={()=>setCreateModalShown(true)} style={{width: "70%", marginLeft: "15%", marginBottom: 30}}>
          Challenge a Friend
        </Button>
        <Button variant="secondary" onClick={()=>logout()} style={{width: "70%", marginLeft: "15%", marginBottom: 30}}>
          Log out
        </Button>

        {
          Object.keys(myMatches).length === 0 ?
          <></>
          :
          <>
        <h4 className='Auth-form-title'>
          My Matches:
        </h4>
        <br/>
        {
          Object.keys(myMatches).map((matchId) => {
            return (
              <div className= "d-flex">
              <Button onClick={()=>joinMatch(myMatches[matchId], matchId)} style={{display: "inline", marginInline: 20}} key={matchId} >
              {`Match #${matchId.split('|')[2]} with ${matchId.replace(myUsername, "").replace(matchId.split('|')[2], "").replaceAll("|","")}`}
              </Button>
              <Button className='ml-3' variant="secondary" onClick={()=> deleteMatch(matchId)}>
                Delete
              </Button>
              </div>
            )
          })
        }</>
      }
        </div>
    </div> </> */}
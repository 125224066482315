import React, { useState } from 'react'
import { ReactComponent as BoardSvg } from '../assets/icons/nav/board.svg';
import { ReactComponent as Person } from '../assets/icons/nav/person.svg';
import { ReactComponent as Bell } from '../assets/icons/nav/bell.svg';
import { ReactComponent as Premium } from '../assets/icons/nav/membership.svg';
import { ReactComponent as Shield } from '../assets/icons/nav/shield.svg';

import silhouette from "../assets/icons/silhouette.jpg";
import { useData } from '../backend/firebase';

const Settings = () => {
  const { myAccount } = useData()

  const [selectedTab, setSelectedTab] = useState(0)

  const tabItems=[
    {
      icon: <Person style={{width: 25, height: 25, fill: "white"}}/>,
      title: "Profile",
      card: (
      <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", paddingBottom: 15 }}>
        <img src={silhouette} style={{ height: 80, width: 80, borderRadius: 50, border: "solid", borderColor: "#5C6A8A" }}/>
        <p style={{ marginTop: 15 }}>
          {myAccount["username"]}
        </p>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "row" }}>
          <p style={{ height: "fit-content" }}>Email address</p>
          <input/>
        </div>
      </div>)
    },
    {
      icon: <BoardSvg style={{width: 25, height: 25, fill: "white"}}/>,
      title: "Themes",
      card: (
      <>
        Themes
      </>)
    },
    {
      icon: <Bell style={{width: 27, height: 27, fill: "white"}}/>,
      title: "Notifications",
      card: (
      <>
        Notifications  
      </>)
    },
    {
      icon: <Premium style={{width: 27, height: 27, fill: "white"}}/>,
      title: "Membership",
      card: (
      <>
        Membership  
      </>)
    },
    {
      icon: <Shield style={{width: 27, height: 27, fill: "white"}}/>,
      title: "Privacy",
      card: (
      <>
        Privacy  
      </>)
    },
  ]

  return (
    <div style={{width: "100%",height: "100%", display: "flex", backgroundColor: "#0C0A1A", flexDirection: "row", color:'white'}}>

<div className="SettingsNav">
        <ul className="SidebarList">
            {
                tabItems.map((val, key) => (
                    <li
                        key={key+1}
                        className="row"
                        style={{
                          // backgroundColor: "#6275a7",
                          // borderRadius: 20,
                          marginBottom: key === tabItems.length-1 ? 0 : 15
                        }}
                        id={selectedTab == key ? "active" : ""}
                        onClick={()=>{
                          setSelectedTab(key)
                            // window.location.pathname = val.link;
                        }}
                    >
                        <div id="icon">{val.icon}</div> <div id="title">{val.title}</div>
                        {/* HELLo */}
                    </li>
                ))
            }
        </ul>
    </div>
    <div className="SettingsCard">
        {
          tabItems[selectedTab].card
        }
    </div>
    </div>
  )
}

export default Settings



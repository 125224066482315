import React, { useState, useEffect } from 'react'
import '../styles/board.css'
import { useData } from '../backend/firebase';
import { Match } from '../components/match';
import silhouette from '../assets/icons/silhouette.jpg'
import { unRankBoard } from '../utils/logic';
import { ReactComponent as Chevron } from '../assets/icons/nav/chevron.svg';



const HistoryViewer = ({history, setHistory}) => {
    const [moveNum, setMoveNum] = useState(0)

    const [boardWidth, setWidth] = useState(Math.min(window.innerWidth,window.innerHeight*7/8)*.80)
    const { myAccount } = useData()

    const [turn, setTurn] = useState(1)

    const [boardOrientation, setOrientation] = useState('white')
    const [myTurn, setMyTurn] = useState(1)

    const handleResize = e => {
        setWidth(Math.min(window.innerWidth,window.innerHeight*7/8)*.80)
    };
    console.log("yhist", history.history[moveNum], moveNum)

    useEffect(() => {
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        }
    }, []);

    return (
        <div  className="center-screen" style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", width:"100%"}}>
            <div style={{position: "relative", display: "flex", height: "100vh", paddingInline: 50,  alignItems: "center", justifyContent: "end", flexDirection: "column", paddingBottom: 35}}>
                
            <div onClick={()=>setHistory("")} style={{ position: "absolute", top: 45, left: 80, backgroundColor: "#2C3A4A", padding: 5, borderRadius: 50, cursor: "pointer", marginRight: 20, paddingRight: 20, fontWeight: 600 }} disabled={0 === moveNum}>
                        <Chevron style={{width: 30, height: 30, transform: `rotate(180deg)`}}/> Back
                    </div>
                
                <div className='mx-3' style={{backgroundColor: "#0C1A2A", borderRadius: 15, display: "flex",flexDirection: "row", justifyContent: "center",  alignItems: "center", width: 200, paddingBlock: 20, height: "fit-content"}}>
                    <div onClick={()=>setMoveNum(moveNum-1)} style={{display: 0 === moveNum ? "none" : "", backgroundColor: "#2C3A4A", padding: 5, borderRadius: 50, cursor: "pointer", marginRight: 20,  transform: `rotate(180deg)`,}} disabled={0 === moveNum}>
                        <Chevron style={{width: 30, height: 30}}/>
                    </div>
                    <div onClick={()=>setMoveNum(moveNum+1)} style={{ display: history.history.length === moveNum+1 ? "none" : "", backgroundColor: "#2C3A4A", padding: 5, borderRadius: 50, cursor: "pointer"}}>
                        <Chevron style={{width: 30, height: 30}}/>
                    </div>
                    {/* <button onClick={()=>setMoveNum(moveNum-1)} disabled={0 === moveNum}>
                        Prev
                    </button>
                    <button onClick={()=>setMoveNum(moveNum+1)} disabled={history.history.length === moveNum+1}>
                        Next
                    </button>
                    <button onClick={()=>setHistory("")}>
                        Exit
                    </button> */}
                </div>
            </div>
            <div style={{flex: "50%", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
                <div className="UserTag mb-4" onClick={()=>{ alert("profile card (modal?)")}} style={{height:45, width: (boardWidth*7/8)*4/4 + 30, float: "left", marginTop: 0, border: moveNum % 2 === 0 ? "solid #777" : "", borderWidth: "1.5px"}}>
                    <div id="icon" style={{flex: "12%"}}><img src={silhouette} style={{width: 25, height: 25, borderRadius: 15}}/></div> <div id="title" style={{flex: "88%", display: "flex", float: "left", textAlign: "center"}}>{history.boardId.replace(myAccount?.username, "").replace(history.boardId.split('|')[2], "").replaceAll("|","")}</div>
                </div>
                <div className="mt-8" style={{  width: "fit-content", height: "fit-content", padding: 15, borderRadius: 10, backgroundColor: "#0C1A2A"}}>
                    <Match boardWidth={boardWidth} state={unRankBoard(history.history[moveNum])} boardOrientation={history.isWhite ? "white" : "black"} turn={-1}/>
                </div>
                <div className="UserTag mt-4" onClick={()=>{ alert("profile card (modal?)")}} style={{height:45, width: (boardWidth*7/8)*4/4 + 30, float: "left", marginTop: 0, border: moveNum % 2 === 1 ? "solid #777" : "", borderWidth: "1.5px", color:"white"}}>
                    <div id="icon" style={{flex: "12%"}}><img src={silhouette} style={{width: 25, height: 25, borderRadius: 15}}/></div> <div id="title" style={{flex: "88%", display: "flex", float: "left", textAlign: "center"}}>{myAccount?.username ?? "Player"}</div>
                </div>
            </div>
            <div style={{height: "100vh", flexBasis: "25%"}}>
            </div>
        </div>
    )
}

export default HistoryViewer
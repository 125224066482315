import React, { useState } from "react"
import { ReactComponent as Play } from '../assets/icons/nav/play.svg';
import { ReactComponent as Home } from '../assets/icons/nav/home.svg';
import { ReactComponent as History } from '../assets/icons/nav/history.svg';
import { ReactComponent as Settings } from '../assets/icons/nav/settings.svg';
import { ReactComponent as Friends } from '../assets/icons/nav/friends.svg';
import { ReactComponent as Exit } from '../assets/icons/nav/exit2.svg';
import { ReactComponent as Help } from '../assets/icons/nav/help.svg';
import fkIcon from "../assets/icons/fk-icon.png";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useData } from "../backend/firebase";

export default function SideBar({nav, setNav})
{
    const [logoutModalShown, setLogoutModalShown] = useState(false)
    let {logout} = useData()

    let items  = [
        // {
        //   link: "/home",
        //   icon: <Home style={{width: 22, height: 22, fill: "white"}}/>,
        //   title: "Home"
        // },
        {
          link: "/play",
          icon: <Play style={{width: 25, height: 25}}/>,
          title: "Play"
        },
        {
          link: "/friends",
          icon: <Friends style={{width: 25, height: 25, fill: "white"}}/>,
          title: "Friends"
        },
        {
          link: "/history",
          icon: <History style={{width: 22, height: 22, fill: "white"}}/>,
          title: "History"
        },
        {
          link: "/settings",
          icon: <Settings style={{width: 25, height: 25, fill: "white"}}/>,
          title: "Settings"
        },
      ]
    
      console.log(window.location.pathname)
        
    return (
        <>
        <div className="ModalShade" hidden={!logoutModalShown} onClick={()=>setLogoutModalShown(false)}>
          <div className="ModalBox" hidden={!logoutModalShown} onClick={()=>{}}>
            <h3 className="ModalTitle">Logout?</h3>
            <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", width: "fit-content", marginInline: "auto"}}>
              <div className="ModalButton" onClick={()=>setLogoutModalShown(false)}>
                No
              </div>
              <div className="ModalButton" id="danger" style={{marginLeft: 30}} onClick={()=>logout()}>
                Yes
              </div>
            </div>
          </div>
        </div>
        <div className="Sidebar">
            <div className="SidebarTitle" id={nav == 0 ? "active" : ""} style={{height: 150}}
                            onClick={()=>{
                                setNav(0)
                                // window.location.pathname = val.link;
                            }}>
                
                <div id="icon"><img src={fkIcon} style={{width: 35, height: 35, fill: "white"}}/></div> <div id="title">Five Knights</div>
            
            </div>
            <ul className="SidebarList">
                {
                    items.map((val, key) => (
                        <li
                            key={key+1}
                            className="row"
                            id={nav == key+1 ? "active" : ""}
                            onClick={()=>{
                                setNav(key+1)
                                // window.location.pathname = val.link;
                            }}
                        >
                            <div id="icon">{val.icon}</div> <div id="title">{val.title}</div>
                            {/* HELLo */}
                        </li>
                    ))

                    
                }
            </ul>
            <ul className="SidebarList" style={{flex: "90%", justifyContent: "end", fontSize: 14}}>
            <li
                    className="row"
                    onClick={()=>{ var win = window.open("https://sabil.ca", '_blank');}}
                    style={{height:50, color: "#ccc"}}
                >
                    <div id="icon"><Help style={{width: 25, height: 25, fill: "#ccc" }}/></div> <div id="title">Help</div>
                    {/* HELLo */}
                </li><li
                    className="row"
                    onClick={()=>{ setLogoutModalShown(true)}}
                    style={{height:50, color: "#ccc"}}
                >
                    <div id="icon"><Exit style={{width: 25, height: 25, }}/></div> <div id="title">Logout</div>
                    {/* HELLo */}
                </li>
                {/* <p style={{fontSize: 12, marginTop: 20, color: "#ccc", paddingRight: 10, justifyContent: "center", alignItems: "center", display: "flex", flexDirection: "column"}}>
                    <a>Privacy policy</a> 
                    <a>Terms and conditions</a> 
                </p> */}
                <p style={{fontSize: 12, marginTop: 20, color: "#ccc", paddingRight: 10}}>
                    Version: 1.0.1
                </p>
                <p style={{fontSize: 12, marginTop: 0, color: "#ccc", paddingRight: 10}}>
                    Powered by: Kaldera Tech&reg;
                </p>
            </ul>
        </div>
        </>
    )
}
import React from 'react'
import { Match } from './match'
import { ReactComponent as Trash } from '../assets/icons/trash.svg';
import silhouette from '../assets/icons/silhouette.jpg'


export const CarouselMatch = ({state, avatar, playerName, onClick, isMyTurn, boardOrientation, onDelete}) => {
  return (
    <div style={{width: "auto", borderRadius: 15, marginRight: 30, height: "max-content", backgroundColor: isMyTurn && false ? "#13291a" : "#0C1A2A", border: isMyTurn ? "solid" : "", borderWidth: 2, borderColor: "#33493a", display:"flex", flexDirection: "column", justifyContent: "center", alignItems: "center", }}>
          <div style={{margin: 10, marginBottom: 0, borderRadius: 15, cursor:"pointer"}}  onClick={()=>onClick()}>
            <Match boardOrientation={boardOrientation} boardWidth={250} state={state} turn={-1} isRounded={true}/>
          </div>

            <div style={{width: "90%", fontSize: 12, color: "white", display: "flex", flexDirection:"row" }}>
                <div className="UserTag mb-3 mt-3" onClick={()=>{ alert("profile card (modal?)")}} style={{height:40, width: "75%", flex: "75%", boxShadow: ""}}>
                    <div id="icon"><img src={avatar === undefined ? silhouette : avatar} style={{width: 25, height: 25, borderRadius: 15}}/></div> <div id="title" style={{flex: "80%"}}>{playerName}</div>
                </div>
                <div className="UserTag mb-3 mt-3" onClick={()=>onDelete()} style={{height:40, width: "20%", flex: "20%", marginLeft: "5%", backgroundColor: "#603030", border:"", borderColor:"#0C1A2A33", boxShadow: ""}}>
                    <Trash style={{width: 17, height: 17, fill: "#ccc"}}/>
                </div>
            </div>
          {/* <div style={{ display: "flex", color: "white", margin: 10, flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
            

          </div> */}
        </div>
  )
}

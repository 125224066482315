import { useState } from 'react'
import { Ball } from './ball'
import { Knight } from './knight'
import {Board} from './newboard'
import { ballMoveValid, moveValid } from '../utils/logic'
import { Legal } from './legal'


export function Match({boardOrientation, boardWidth, state, setState, turn, isRounded, currentlySelected, setCurrentlySelected}) {
    const rotatedState = [
        [0, 1, 1, 3, 1, 1, 0],
        [0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0],
        [0, 2, 2, 4, 2, 2, 0],
      ]

    for(let i = 0; i < 8; i++)
        {for(let j = 0; j < 7; j++)
            {if(boardOrientation === "white")
                rotatedState[7-i][6-j] = state[i][j]
            else
                rotatedState[i][j] = state[i][j]
            }
}
    
    const [legalMoves, setLegalMoves] = useState([])
    function onRelease(i, j, newPos)
    {
        console.log("releaes ",i,j, newPos)
        if(newPos === undefined || (i==newPos.y && j == newPos.x))return;
        setCurrentlySelected(undefined)
        setLegalMoves([])// !moveValid(i, j, newPos.y, newPos.x)
        if(newPos === undefined || !legalMoves.some(e => e.x === newPos.x && e.y === newPos.y)) return
        
        let copy = [...rotatedState]
        let temp = copy[i][j]
        copy[i][j] = copy[newPos.y][newPos.x]
        copy[newPos.y][newPos.x] = temp


        const rotatedCopy = [
            [0, 1, 1, 3, 1, 1, 0],
            [0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0],
            [0, 2, 2, 4, 2, 2, 0],
          ]
    
        for(let i = 0; i < 8; i++)
                {for(let j = 0; j < 7; j++)
                    {if(boardOrientation === "white")
                        rotatedCopy[7-i][6-j] = copy[i][j]
                    else
                    rotatedCopy[i][j] = copy[i][j]
                    }
        }

        setState(rotatedCopy)
    }

    function onClick(pos)
    {
        const legal = []
        if(rotatedState[pos.y][pos.x] > 2)
            {for(let i = 0; i < 7; i++)
                for(let j = 0; j < 8; j++)
                    if(rotatedState[j][i] !== 0 && rotatedState[j][i] % 2 === rotatedState[pos.y][pos.x] % 2 && rotatedState[j][i] !== rotatedState[pos.y][pos.x] && ballMoveValid(rotatedState, rotatedState[pos.y][pos.x], pos.y, pos.x, j, i, [0,0,0,0,0]))
                        legal.push({x: i, y: j})}
        else
           { for(let i = -2; i < 3; i++)
                for(let j = -2; j < 3; j++)
                    if(i!=j && moveValid(pos.x, pos.y, pos.x+i, pos.y+j) && rotatedState[pos.y+j][pos.x+i] == 0)
                        legal.push({x: pos.x+i, y: pos.y+j})}

        setLegalMoves(legal)
    }

    return (
        <div style={{position: "relative",height: boardWidth,width: boardWidth*7/8, overflow: ""}}>
            <Board boardWidth={boardWidth} boardOrientation={boardOrientation} isRounded={isRounded} curSel={currentlySelected} onClick={onRelease} legalMoves={legalMoves}/>
            {
                rotatedState.map((row, i) => row.map((cell, j) => cell === 0 ? <></> : <Knight boardWidth={boardWidth} x={j} y={i} cell={cell} onRelease={(newPos) => onRelease(i, j, newPos)} onClick={ (coords)=>{onClick(coords); setCurrentlySelected({i:i,j:j}) }} canMove={cell % 2 == turn % 2} curSel={currentlySelected}/>))
            }
            {
                rotatedState.map((row, i) => row.map((cell, j) => cell <= 2 ? <></> : <Ball boardWidth={boardWidth} x={j} y={i} color={(cell%2) === 0 ? "b" : "w"} cell={cell} onRelease={(newPos) => onRelease(i, j, newPos)} onClick={ (coords)=>{onClick(coords); setCurrentlySelected({i:i,j:j}) }} canMove={cell % 2 == turn % 2} curSel={currentlySelected}/>))
            }
            {
                legalMoves.map(cell => <Legal boardWidth={boardWidth} x={cell.x} y={cell.y} />)
            }
        </div>
    ) 
  }
import '../styles/board.css'
export function Board({boardOrientation, boardWidth, curSel, isRounded, onClick, legalMoves}) {

  const COLUMNS = "abcdefgh".split("");

  const colors = {
    // tileBlack: '#151515',
    tileBlack: '#000A1A',
    // tileWhite: '#8776BD',
    tileWhite: '#5C6A7A',

    // tileSelectedBlack: '#050505',
    tileSelectedBlack: '#202A3A',
    tileSelectedWhite: '#7C8A9A',

    pieceBlack: '#6c6c6c',
    pieceWhite: '#90615A',

    ballBlack: '#593C1F',
    ballWhite: 'tan',

    legalPiece: 'pink',
    legalBall: 'pink',
  };

  const sel = curSel === undefined ? "" : (boardOrientation === "black" ? (COLUMNS[6-curSel.j] + (curSel.i+1)) : (COLUMNS[curSel.j] + (8-curSel.i)))

  console.log(sel)

  return (
    <div style={{zIndex: 0, position:"absolute",}}>
      {[...Array(8)].map((_, r) => {
        return (
          <div
            key={r.toString()}
            style={{
              display: "flex",
              flexWrap: "nowrap",
              maxWidth: boardWidth,
            }}
          >
            {[...Array(7)].map((_, c) => {
                const square = boardOrientation === "black"
                    ? ((COLUMNS[6 - c] + (r + 1)))
                    : ((COLUMNS[c] + (8 - r)));
                const squareColor =  boardOrientation === "black" ? (c % 2 === r % 2 ? "white" : "black") :  (c % 2 === r % 2 ? "black" : "white");
                return (
                    <div
                        onClick= {()=>{ console.log("square", curSel, r, c) ;if(sel!=="") onClick(curSel.i, curSel.j, {x: c, y: r}) }}//onClick({x:curSel.j, y: curSel.i}, boardOrientation === "black" ? (r) : (7-r), boardOrientation === "black" ? (c) : (6-c))
                        style={{
                            margin:0,
                            padding:0,
                            zIndex: 1,
                            borderRadius: isRounded === true ? `${r==0&&c==0? "12px" : "0px"} ${r==0&&c==6? "12px" : "0px"} ${r==7&&c==6? "12px" : "0px"} ${r==7&&c==0? "12px" : "0px"}` : 0,
                            // borderRadius: 15,
                            width: boardWidth / 8,
                            height: boardWidth / 8,
                            display: "flex",
                            justifyContent: "center",
                            cursor: legalMoves != undefined && legalMoves.some(e => e.x === c && e.y === r) ? "pointer" : "",
                            backgroundColor: sel == square ? (squareColor === "black" ? colors.tileSelectedBlack : colors.tileSelectedWhite) : (squareColor === "black" ? colors.tileBlack : colors.tileWhite),
                        }}
                    />
                );
            })}
          </div>
        )}
      )
    }
    </div>
  ) 
}
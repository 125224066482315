import { useState } from "react"

export function Ball({boardWidth, x, y, cell, onRelease, onClick, canMove, color, curSel}) {
    const size = boardWidth / 20
    const svgSize = 512
    const stroke = 25
    const white = "#bbb"
    const black = "#444"
    const c = color === "w" ? black : white

    const [pos, setPos] = useState({x: 0, y: 0})
    const [dragging, setDragging] = useState(false)
    const [rel, setRel] = useState({x: 0, y: 0})
    
    const dragDist = 1000
    const draggableDist = 40
    const sizeUp = 3

    
    function getIandJ(){
        const diffX = Math.floor((pos.x + boardWidth/16) / (boardWidth/8))
        const diffY = Math.floor((pos.y + boardWidth/16) / (boardWidth/8))

        return { x: x + diffX, y: y+ diffY}
    }

    return (
        <svg
        onMouseDown={(e)=>{ if(dragging || !canMove) return; onClick({x:x, y:y}); setDragging(true); const r = {x: e.pageX-rel.x-e.nativeEvent.offsetX+  size/2+draggableDist/2, y: e.pageY-rel.y-e.nativeEvent.offsetY + size/2+draggableDist/2}; setRel(r); e.stopPropagation(); e.preventDefault() }}// setPos({ x: e.pageX - r.x, y: e.pageY-r.y});
        onMouseMove={(e)=>{ if(!dragging) return; setPos({ x: e.pageX - rel.x, y: e.pageY-rel.y}); e.stopPropagation(); e.preventDefault()} }
        onMouseUp={(e)=>{ if(!dragging) return; setDragging(false); setRel({ x: 0, y: 0 }); setPos({ x: 0, y: 0 }); if(onRelease) onRelease(getIandJ()); e.stopPropagation(); e.preventDefault() }}
        onMouseLeave={(e)=>{if(!dragging) return; setDragging(false); setRel({ x: 0, y: 0 }); setPos({ x: 0, y: 0 }); if(onRelease) onRelease(undefined);  e.stopPropagation(); e.preventDefault()}}
        
        xmlns="http://www.w3.org/2000/svg"
            data-name="Layer 1"
            viewBox={`0 0 512 512`}
            style={{
                top: y * (boardWidth/8) + (boardWidth/16 - size/2)-(dragging? dragDist/2 : draggableDist/2),
                left: x * (boardWidth/8) + (boardWidth/16 - size/2)-(dragging? dragDist/2 : draggableDist/2),
                position:'absolute', transform: `translate(${pos.x}px, ${pos.y}px)`,
                width: size + (dragging ? dragDist : draggableDist),
                height: size + (dragging ? dragDist : draggableDist),
                padding: (dragging? (dragDist/2 - sizeUp) : draggableDist/2),
                cursor: canMove ? (dragging ? "grabbing" : "grab") : "",
                zIndex: (dragging ? 10 : 0),
                overflow: 'auto',
                pointerEvents: curSel === undefined || (curSel.i === y && curSel.j === x) ? '' : 'none'
            }}
        >
       <radialGradient
            id="b"
            cx={svgSize/2}
            cy={svgSize/2}
            r={svgSize/2}
            fx={svgSize/2}
            fy={svgSize/2}
            gradientUnits="userSpaceOnUse"
        >
            <stop offset={0} stopColor= {"#aaa"}/>
            <stop offset={0.6} stopColor= {"#999"} />
            <stop offset={1} stopColor= {"#666"} />
        </radialGradient>
        <radialGradient
            id="w"
            cx={svgSize/2}
            cy={svgSize/2}
            r={svgSize/2}
            fx={svgSize/2}
            fy={svgSize/2}
            gradientUnits="userSpaceOnUse"
        >
        <stop offset={0} stopColor= {"#666"}/>
        <stop offset={0.6} stopColor= {"#555"} />
        <stop offset={1} stopColor= {"#333"} />
        </radialGradient>
        <rect
            width={svgSize} height={svgSize}
            x={0} y={0}
            rx={svgSize/2} ry={svgSize/2}
            style={{ fill: "#000000aa" }}
        />
        <rect
            width={svgSize-stroke} height={svgSize-stroke}
            x={stroke/2} y={stroke/2}
            rx={svgSize/2} ry={svgSize/2}
            style={{ fill: `url(#${color})` }}
        />
    </svg>
    ) 
  }


// export function Ball({boardWidth, x, y, props, color, cell}) {
//     const size = boardWidth / 18
//     const svgSize = 512
//     const stroke = 25
//     const white = "#bbb"
//     const black = "#444"
//     const c = color === "w" ? black : white
//     return (
//         <div>
//         <svg
//             xmlns="http://www.w3.org/2000/svg"
//             data-name="Layer 1"
//             viewBox={`0 0 512 512`}
//             style={{top: y * (boardWidth/8) + (boardWidth/16 - size/2), left: x * (boardWidth/8) + (boardWidth/16 - size/2), position:'absolute', 
//             width: size,
//             height: size}}
//             {...props}
//         >
//         {/* <defs> */}
//         {/* <radialGradient
//             id="a"
//             cx={svgSize/2}
//             cy={svgSize/2}
//             r={svgSize/2}
//             fx={svgSize/2}
//             fy={svgSize/2}
//             gradientUnits="userSpaceOnUse"
//         >
//             <stop offset={0} stopColor= {c[0]}/>
//             <stop offset={1} stopColor= {c[1]} />
//         </radialGradient> */}
//         {/* </defs> */}
//         <rect
//         width={svgSize}
//         height={svgSize}
//         x={0}
//         y={0}
//         rx={svgSize/2}
//         ry={svgSize/2}
//         style={{
//             fill: "#000",
//         }}
//         />
//         <rect
//         width={svgSize-stroke}
//         height={svgSize-stroke}
//         x={stroke/2}
//         y={stroke/2}
//         rx={svgSize/2}
//         ry={svgSize/2}
//         style={{
//             fill: c,//"url(#a)",
//             // stroke: "#000",
//             // strokeWidth: 15,
//             // strokeLinecap: "round"
//         }}
//         />
//     </svg>
//     </div>
//     ) 
//   }

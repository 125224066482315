export function ballMoveValid(state, pc, x1, y1, x2, y2, investigatedSquares)
{
  // const reachableLocations = [
  //   [0, 1, 1, 3, 1, 1, 0],
  //   [0, 0, 0, 0, 0, 0, 0],
  //   [0, 0, 0, 0, 0, 0, 0],
  //   [0, 0, 0, 0, 0, 0, 0],
  //   [0, 0, 0, 0, 0, 0, 0],
  //   [0, 0, 0, 0, 0, 0, 0],
  //   [0, 0, 0, 0, 0, 0, 0],
  //   [0, 2, 2, 4, 2, 2, 0],
  // ]
  if(investigatedSquares.includes(x1*8+y1+1)) return false
  for(let i = 0; i < 5; i++){ if(investigatedSquares[i]==0) {investigatedSquares[i] = x1*8+y1+1; break}}

  for(let i = x1+1; i < 8; i++)
  {
    let p = state[i][y1]
    if(p == 0) continue

    if(p % 2 == pc % 2)
    {
      if(i == x2 && y1 == y2) return true
    else
    {
      if (p % 2 == pc % 2 && ballMoveValid(state, pc,i,y1,x2,y2,investigatedSquares)) return true
      break
    }
  }

    if(p % 2 != pc % 2) break;
  }

  
  for(let i = x1-1; i >= 0; i--)
  {
    let p = state[i][y1]
    if(p == 0) continue
    if(p % 2 == pc % 2)
    {
      if(i == x2 && y1 == y2) return true
    else
    {
      if (p % 2 == pc % 2 && ballMoveValid(state, pc,i,y1,x2,y2, investigatedSquares)) return true
      break
    }
  }

    if(p % 2 != pc % 2) break;
  }



  for(let i = y1+1; i < 7; i++)
  {
    if(state[x1][i] == 0) continue
    if(state[x1][i] % 2 == pc % 2)
    {
      if(x1 == x2 && i == y2) return true
    else
    {
      if (state[x1][i] % 2 == pc % 2 && ballMoveValid(state, pc,x1,i,x2,y2, investigatedSquares)) return true
      break
    }
  }

    if(state[x1][i] % 2 != pc % 2) break;
  }

  
  for(let i = y1-1; i >= 0; i--)
  {
    if(state[x1][i] == 0) continue
    if(state[x1][i] % 2 == pc % 2)
    {
      if(x1 == x2 && i == y2) return true
    else
    {
      if (state[x1][i] % 2 == pc % 2 && ballMoveValid(state, pc,x1,i,x2,y2, investigatedSquares)) return true
      break
    }
  }

    if(state[x1][i] % 2 != pc % 2) break;
  }

  
  for(let i = 1; i < 8; i++)
  {
    let x = x1+i
    let y = y1+i
    if(x > 7 || y > 6) break
    if(state[x][y] == 0) continue

    if(state[x][y] % 2 == pc % 2)
    {
      if(x == x2 && y == y2) return true
    else
    {
      if (state[x][y] % 2 == pc % 2 && ballMoveValid(state, pc,x,y,x2,y2, investigatedSquares)) return true
      break
    }
  }

    if(state[x][y] % 2 != pc % 2) break;
  }
  for(let i = 1; i < 8; i++)
  {
    let x = x1+i
    let y = y1-i
    if(x > 7 || y < 0) break
    if(state[x][y] == 0) continue
    if(state[x][y] % 2 == pc % 2)
    {
      if(x == x2 && y == y2) return true
    else
    {
      if (state[x][y] % 2 == pc % 2 && ballMoveValid(state, pc,x,y,x2,y2, investigatedSquares)) return true
      break
    }
  }

    if(state[x][y] % 2 != pc % 2) break;
  }
  for(let i = 1; i < 8; i++)
  {
    let x = x1-i
    let y = y1-i
    if(x < 0 || y < 0) break
    if(state[x][y] == 0) continue
    if(state[x][y] % 2 == pc % 2)
    {
      if(x == x2 && y == y2) return true
    else
    {
      if (state[x][y] % 2 == pc % 2 && ballMoveValid(state, pc,x,y,x2,y2, investigatedSquares)) return true
      break
    }
  }

    if(state[x][y] % 2 != pc % 2) break;
  }
  for(let i = 1; i < 8; i++)
  {
    let x = x1-i
    let y = y1+i
    if(x < 0 || y > 6) break
    if(state[x][y] == 0) continue
    if(state[x][y] % 2 == pc % 2)
    {
      if(x == x2 && y == y2) return true
    else
    {
      if (state[x][y] % 2 == pc % 2 && ballMoveValid(state, pc,x,y,x2,y2, investigatedSquares)) return true
      break
    }
  }

    if(state[x][y] % 2 != pc % 2) break;
  }

  return false
}

export function moveValid(x1, y1, x2, y2)
{
  if(x2 < 0 || x2 > 6 || y2 < 0 || y2 > 7) return false;
  
  const x = Math.abs(x1-x2)
  const y = Math.abs(y1-y2)
  return (x == 2 && y == 1) || (x == 1 && y == 2)
}

export function getAllLegalMoves(state, turn){
  const legal = []
  for(let x = 0; x < 7; x++){
    for(let y = 0; y < 8; y++){
      if(state[y][x] === 0 || state[y][x]%2 !== 2-turn) continue
      
      if(state[y][x] > 2)
          {for(let i = 0; i < 7; i++)
              for(let j = 0; j < 8; j++)
                  if(state[j][i] !== 0 && state[j][i] % 2 === state[y][x] % 2 && state[j][i] !== state[y][x] && ballMoveValid(state, state[y][x], y, x, j, i, [0,0,0,0,0]))
                      legal.push({x1: x, y1: y, x2: i, y2: j})}
      else
          { for(let i = -2; i < 3; i++)
              for(let j = -2; j < 3; j++)
                  if(i!=j && moveValid(x, y, x+i, y+j) && state[y+j][x+i] == 0)
                      legal.push({x1: x, y1: y ,x2: x+i, y2: y+j})}

    }
  }
  return legal
}


/* global BigInt */
export function rankBoard(brd, trn)
{
   let k1 = 0, k2 = 0
   // let pieces = [undefined, undefined,      undefined, undefined, undefined, undefined,     undefined, undefined, undefined, undefined]
   let rank = 0x0n
   let three = BigInt(0x3)


   for(let i = 0x7n; i >= 0x0n; i--)
   {
       for(let j = 0x0n; j < 0x7n; j++)
       {
           if(brd[i][j] == 0) continue;


           // let jj = sym == -1 ? (6-j) : j
          
          
             if(brd[i][j] > 2)
             {
                 let shift = BigInt(48+((brd[i][j]-3)*6))
                 let num = BigInt((i<<three)+j)
                 rank |= (num << shift)
                 console.log(rank.toString(8))
             }
             else
             {
                 let shift = brd[i][j] == 1 ? ((k2)*6) : ((k1)*6+24)
                 brd[i][j] == 1 ? (k2+=1) : (k1+=1)
                 let num = BigInt((i<<three)+j)
                 rank |= (num << BigInt(shift))
                 console.log(rank.toString(8))
             }
       }
   }
   if(trn == 2){rank |= 0x1000000000000000n}
   console.log(rank.toString(8))
  //  let rnk = new Uint8Array(8)
  let rnk = ""
  console.log("RANK: " + rank.toString(8))
  for (let i = 7; i>=0; i-=1){
    // rnk[i] = (Number)(rank & 0xFFn)
    rnk+=String.fromCharCode((Number)(rank & 0xFFn))
    console.log("rank: char at " + i + " is: " + String.fromCharCode((Number)(rank & 0xFFn)) + " int " + ((Number)(rank & 0xFFn)).toString(8))
    rank>>=8n
  }
   return rnk
}
// 1063966404230004865
// 2384247505325064916480

// 1005000513471675741416
// 73037574727105040201
// 402400245634736760607000


export function unRankBoard(rnk)
{
  console.log("yhist", rnk)
  // convert from byte array to bigInt
  const bbo = [
    [0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0],
  ]
 console.log(bbo)
 let rank= BigInt(0)
  // for (let i = 0; i <=7; i+=1){
  for (let i = 7; i>=0; i-=1){
    // rank|= BigInt(rnk[i])
    console.log("un rank: char at " + i + " is: " + rnk.charAt(i) + " int " + rnk.charCodeAt(i).toString(8))
    rank<<=8n
    rank|= BigInt(rnk.charCodeAt(i))
    
    console.log(rank.toString(8))
}

  console.log(rank.toString(8))


 let seven = BigInt(0x7)
 let three = BigInt(0x3)
//  for(let a = 0; a<8; a++)
//  {
//   bbo[a] = []
//   //  for(let b=0; b<7; b++)
//   //  {
//   //    bbo[a][b] = 0
//   //  }
//  }
 console.log("bbo")
 console.log(bbo)
 console.log("postbbo")
//  return bbo
 // let pieces = [undefined, undefined,      undefined, undefined, undefined, undefined,     undefined, undefined, undefined, undefined]
   let i = 0, j = 0
   for(let _ = 0; _ < 4; _++)
   {
       j = rank&seven
       rank>>=three
       i = rank&seven
       rank>>=three
       bbo[i][j] = 1
   }
   for(let _ = 0; _ < 4; _++)
   {
       j = rank&seven
       rank>>=three
       i = rank&seven
       rank>>=three
       bbo[i][j] = 2
   }
   j = rank&seven
   rank>>=three
   i = rank&seven
   rank>>=three
   bbo[i][j] = 3
   j = rank&seven
   rank>>=three
   i = rank&seven
   rank>>=three
   bbo[i][j] = 4
   console.log(bbo)
   return bbo//[brd, rank+1n]
}

export function isOver(state)
{
  for(let i = 0; i<7; i++){
    if(state[0][i] == 4) return 1;
    if(state[7][i] == 3) return 2;
  }
  return 0
}
import React, { useEffect, useState } from 'react'
import { useData } from "../backend/firebase";
import '../styles/board.css'

import Button from 'react-bootstrap/Button';
import { ReactComponent as Google } from '../assets/icons/google.svg';
import { ReactComponent as Apple } from '../assets/icons/apple.svg';

const Login = () => {
  let {updateState, db, user, myAccount, login, register, resetPassword} = useData()
  let [authMode, setAuthMode] = useState("signin")

  let [email, setEmail] = useState("")
  let [password, setPassword] = useState("")
  let [password2, setPassword2] = useState("")

  let [error, setErrorMessage] = useState("")

  const changeAuthMode = () => {
    setAuthMode(authMode === "signin" ? "signup" : "signin")
  }

  async function signin(e)
  {
    e.preventDefault(); 

    const res = await login("email", email, password)
    if(res !== undefined) setError(res)
  }

  async function resetPass(e)
  {
    e.preventDefault(); 

    const res = await resetPassword(email)
    if(res !== undefined) alert("reset failed " + res)
    else alert("reset email sent")
  }

  async function createAccount(e)
  {
    e.preventDefault(); 

    if(password !== password2)
    {
      setErrorMessage("Passwords don't match")
      return;
    }

    const res = await register(email, password)
    console.log(res)
    if(res !== undefined) setError(res)
  }

  function setError(err){
    err = `${err}`
    console.log(typeof(err))
    if(err.includes("auth/invalid-email")) setErrorMessage("The provided value for the email user property is invalid. It must be a string email address.")
    else if(err.includes("auth/email-already-exists")) setErrorMessage("The provided email is already in use by an existing user. Each user must have a unique email.")
    else if(err.includes("auth/internal-error")) setErrorMessage("The Authentication server encountered an unexpected error while trying to process the request.")
    else if(err.includes("auth/invalid-credential")) setErrorMessage("The credential used to authenticate the user cannot be used to perform the desired action.")
    else if(err.includes("auth/invalid-display-name")) setErrorMessage("The provided value for the displayName user property is invalid. It must be a non-empty string.")
    else if(err.includes("auth/invalid-id-token")) setErrorMessage("The provided ID token is not a valid Firebase ID token.")
    else if(err.includes("auth/invalid-last-sign-in-time")) setErrorMessage("The last sign-in time must be a valid UTC date string.")
    else if(err.includes("auth/invalid-password")) setErrorMessage("The provided value for the password user property is invalid. It must be a string with at least six characters.")
    else if(err.includes("auth/invalid-photo-url")) setErrorMessage("The provided value for the photoURL user property is invalid. It must be a string URL.")
    else if(err.includes("auth/user-not-found")) setErrorMessage("There is no existing user record corresponding to the provided identifier.")
  }

  return (
    <div className='Auth-form-container'>
    <form onSubmit={authMode === "password" ? resetPass : authMode === "signin" ? signin : createAccount} className="Auth-form">
    { 
    authMode === "password"?
        <div className="Auth-form-content">
          <h3 className="Auth-form-title">Reset Password</h3>
          <div className="form-group mt-4">
            <input
              type="email"
              className="form-control mt-4"
              placeholder="Email"
              value={email}
              required
              onChange={(e)=>setEmail(e.target.value)}
            />
          </div>
          <div className="d-grid gap-2 mt-4">
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </div>
          <p className="text-center mt-4">
            <a onClick={()=>setAuthMode("signin")} style={{ cursor: "pointer", color: "#6699cc", fontWeight: "bold"}}>Back to sign in</a>
          </p>
        </div>
    :
    
    authMode === "signin" ? 
        <div className="Auth-form-content">
          <h3 className="Auth-form-title">Sign In</h3>
          <div className="form-group mt-4">
            <input
              type="email"
              className="form-control mt-4"
              placeholder="Email"
              value={email}
              required
              onChange={(e)=>setEmail(e.target.value)}
            />
          </div>
          <div className="form-group mt-4">
            <input
              type="password"
              className="form-control mt-1"
              placeholder="Password"
              value={password}
              required
              onChange={(e)=>setPassword(e.target.value)}
            />
          </div>
          <p className="text-center mt-4">
            <a onClick={()=>setAuthMode("password")} style={{ cursor: "pointer", color: "#6699cc", fontWeight: "bold"}}>Forgot password?</a>
          </p>
          <div className="d-grid gap-2 mt-4">
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </div>

          {error && <div className="d-grid gap-2 mt-4" style={{color:"#ff7777"}}>
            {`${error}`}
          </div>}
          <div className="text-center mt-4">
            Not registered yet?{" "}
            <span style={{ cursor: "pointer", color: "#6699cc", fontWeight: "bold"}} onClick={changeAuthMode}>
              Register
            </span>
          </div>
        </div>
        :
        <div className="Auth-form-content">
          <h3 className="Auth-form-title">Register</h3>
          
          <div className="form-group mt-4">
            <input
              type="email"
              className="form-control mt-1"
              placeholder="Email"
              value={email}
              required
              onChange={(e)=>setEmail(e.target.value)}
            />
          </div>
          <div className="form-group mt-4">
            <input
              type="password"
              className="form-control mt-1"
              placeholder="Password"
              value={password}
              required
              minLength={6}
              onChange={(e)=>setPassword(e.target.value)}
            />
          </div>
          <div className="form-group mt-4">
            <input
              type="password"
              className="form-control mt-1"
              placeholder="Confirm Password"
              value={password2}
              required
              minLength={6}
              onChange={(e)=>setPassword2(e.target.value)}
            />
          </div>
          <div className="d-grid gap-2 mt-4">
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </div>
          
          
          {error && <div className="d-grid gap-2 mt-4" style={{color:"#ff7777"}}>
            {`${error}`}
          </div>}
          
          <div className="text-center mt-4">
            Already registered?{" "}
            <span style={{ cursor: "pointer", color: "#6699cc", fontWeight: "bold"}} onClick={changeAuthMode}>
              Sign In
            </span>
          </div>
          {/* <p className="text-center mt-2">
            Forgot <a href="#">password?</a>
          </p> */}
        </div>}

        {
          authMode === "password" ? <></>
        :
        <>
        <div className="divider d-flex align-items-center my-4" style={{ marginInline: 30}}>
            <p className="text-center fw-bold mx-3 mb-0" style={{color:'#ffffffdd'}}>Or continue with</p>
        </div>

        
        <div className="d-flex align-items-center justify-content-center my-4">
          <Button onClick={()=>login("google")}  className='mx-3'style={{ backgroundColor: 'white', borderColor: '#00000000'}}>
            <Google style={{width: 20, height: 20, marginBottom: 3}}/>
          </Button>

          <Button onClick={()=>login("apple")} className='mx-3' style={{ backgroundColor: 'white', borderColor: '#00000000'}}>
            <Apple style={{width: 25, height: 25, marginBottom: 3, color: "black"}}/>
          </Button>
          
          {/* <Button onClick={()=>login("x")} className='mx-3' style={{ backgroundColor: 'white', borderColor: '#00000000'}}>
            <X style={{width: 20, height: 20, marginBottom: 3}}/>
          </Button> */}

          {/* <Button onClick={()=>login("facebook")} className='mx-3'style={{ backgroundColor: 'white', borderColor: '#00000000'}}>
            <Facebook style={{width: 20, height: 20, marginBottom: 3}}/>
          </Button> */}
        </div>
        </>}
      </form>
    </div>
  )
}

export default Login
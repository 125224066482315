import React, { useEffect, useState } from 'react'
import { useData } from "../backend/firebase";
import '../styles/board.css'

const AccountCreation = () => {
  let {initializeAccountDoc,checkUsernameExists} = useData()
  let [authMode, setAuthMode] = useState("signin")

  let [username, setUsername] = useState("")
  let [bio, setBio] = useState("")

  const changeAuthMode = () => {
    setAuthMode(authMode === "signin" ? "signup" : "signin")
  }

  async function createAccount(e)
  {
    e.preventDefault(); 

    const valid = await checkUsernameExists(username)
    if(!valid) {
        alert("username already exists!")
        return;
    }
    const res = await initializeAccountDoc(username, bio)
    // if(res === undefined) alert("Registration failed")
  }
//{6,26}
  return (
    <div className='Auth-form-container'>
    <form onSubmit={createAccount} className="Auth-form">
        <div className="Auth-form-content">
          <h3 className="Auth-form-title">Create Your Account</h3>
          <div className="form-group mt-4">
            <input
              type="text"
              className="form-control mt-4"
              placeholder="Username"
              value={username}
              required
              minLength={6}
              maxLength={26}
              pattern="^(?:[a-z,A-Z,\d,_]*)$"
              title={`Username must be alphanumeric and may include underscores`}
              onChange={(e)=>setUsername(e.target.value)}
            />
          </div>
          <div className="form-group mt-4">
            <textarea
              type="text"
              className="form-control mt-1"
              placeholder="Bio"
              value={bio}
              onChange={(e)=>setBio(e.target.value)}
            />
          </div>
          <div className="d-grid gap-2 mt-4 mb-4">
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default AccountCreation
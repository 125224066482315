import React from 'react'

const LoadingScreen = () => {
  return (
    <div style={{width: "100%", height:"100%", backgroundColor: "#0C0A1A", display: "flex", alignItems: "center", justifyContent: "center"}}>
    <div class="Ripple"><div></div><div></div></div>
    </div>
  )
}

export default LoadingScreen
export function Legal({boardWidth, x, y, props, color, cell}) {
    const size = boardWidth / 30
    const svgSize = 512
    const stroke = 20
    return (
        <div
            style={{pointerEvents: "none"}}
        >
        <svg
            xmlns="http://www.w3.org/2000/svg"
            data-name="Layer 1"
            viewBox={`0 0 512 512`}
            style={{top: y * (boardWidth/8) + (boardWidth/16 - size/2), left: x * (boardWidth/8) + (boardWidth/16 - size/2), position:'absolute', 
            width: size,
            height: size}}
            {...props}
        >
        <rect
        width={svgSize}
        height={svgSize}
        x={0}
        y={0}
        rx={svgSize/2}
        ry={svgSize/2}
        style={{
            fill: "black",
        }}
        />
        <rect
        width={svgSize-stroke}
        height={svgSize-stroke}
        x={stroke/2}
        y={stroke/2}
        rx={svgSize/2}
        ry={svgSize/2}
        style={{
            fill: "pink",//"url(#a)",559988
            // stroke: "#000",
            // strokeWidth: 15,
            // strokeLinecap: "round"
        }}
        />
    </svg>
    </div>
    ) 
  }

import React, { useState } from 'react'
import { CarouselMatch } from '../components/CarouselMatch';
import { useData } from '../backend/firebase';
import { unRankBoard } from '../utils/logic';
import HistoryViewer from './HistoryViewer';

export default History = () => {
  const { myHistory, getMyUsername } = useData()
  const [ curHistory, setHistory] = useState("")

  const myUsername = getMyUsername();

  return (
    <div style={{width: "100%",height: "100%", display: "flex", backgroundColor: "#0C0A1A", flexDirection: "row", color:'white', justifyContent: "center", alignItems: "center",}}>
      
      {curHistory !== "" ? 
        <HistoryViewer history={myHistory[curHistory]} setHistory={setHistory}/>
      :
      <div style={{height: "80%",  marginLeft: 50, marginRight: 50, flex: "70%",  flexWrap: "wrap", maxWidth: "100%", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", overflow: "auto"}}>
        {
          Object.keys(myHistory).length == 0 ?
          <p>
            No history
          </p>
          :
          myHistory.reverse().map((match, i) => {
            return (
              <CarouselMatch 
                boardOrientation={match["isWhite"] ? "white" : "black"}
                isMyTurn={(match["isWhite"] ? 1 : 2) === match["turn"] ? true : false}
                onClick={()=>setHistory(i)}
                state={unRankBoard(match["history"][match["history"].length - 1])}
                avatar={match["avatar"]}
                playerName={`${match.boardId.replace(myUsername, "").replace(match.boardId.split('|')[2], "").replaceAll("|","")}`}
              />
            )
          })
        }
      </div>
      }
    </div>
  )
}
import React, { useState } from 'react'
import { ReactComponent as BoardSvg } from '../assets/icons/nav/board.svg';
import { ReactComponent as Person } from '../assets/icons/nav/person.svg';
import { ReactComponent as Bell } from '../assets/icons/nav/bell.svg';
import { ReactComponent as Premium } from '../assets/icons/nav/membership.svg';
import { ReactComponent as Shield } from '../assets/icons/nav/shield.svg';
import { useData } from '../backend/firebase'
import silhouette from "../assets/icons/silhouette.jpg";
import { ReactComponent as Check } from '../assets/icons/nav/check2.svg';
import { ReactComponent as Trash } from '../assets/icons/trash.svg';

const Friends = () => {
    const [searchText, setSearch] = useState("")
    const [result, setResult] = useState([])
    const {searchWithUsername, myAccount, addFriend, removeFriend, sendFriendRequest, removeFriendRequest, removePendingFriendRequest} = useData()

    async function search(val)
    {
        console.log("SEARC")
        const s = val === undefined ? searchText : val
        if(s === "") { setResult([]); return}
        console.log("s2", s)
        const res = await searchWithUsername(s)
        console.log(res)
        setResult(res)
    }

  //   console.log(myAccount)
  // return (
  //   <div style={{width: "100%",height: "100%", display: "flex", backgroundColor: "#0C0A1A", flexDirection: "column", color:'white'}}>
  //       <div className='mt-3'>
  //       <p className='Auth-form-title'>Search</p>
  //     <input
  //               style={{width: "max-content", height: "max-content"}}
  //               type="text"
  //               placeholder="Search for user"
  //               value={searchText}
  //               required
  //               onChange={(e)=>{setSearch(e.target.value);}}
  //               onKeyDown={(e)=> {if(e.keyCode == 13) search()}}
  //             />
  //             <button onClick={()=>search()} style={{width: "max-content", height: "max-content"}}>
  //               Search
  //             </button>
  //             <br/>
  //       {
  //           result.map(x=>(<div className='mt-2 UserTag'>
  //           {x}
  //           {!myAccount["friends"]?.includes(x) && !myAccount["pendingFriendRequests"]?.includes(x) ? 
  //           <button onClick={()=>sendFriendRequest(x)} style={{width: 30, height: 30, marginInline: 5}}>
  //               +
  //             </button>
  //             :
  //           (myAccount["pendingFriendRequests"]?.includes(x) ? <button onClick={()=>removePendingFriendRequest(x)} style={{width: 30, height: 30, marginInline: 5}}>
  //               -
  //             </button> : <></>)}
  //           <br/>
  //           </div>))
  //       }
  //       </div>
  //       <div className='mt-5 mb-5' style={{width: "80%", height: 1, marginInline: "auto", backgroundColor: "#ffffff44", float: "center"}}/>
  //       <div className='mt-3'>
  //         <p className='Auth-form-title'>Friends</p>
  //       <br/>
  //       {
  //             myAccount["friends"]?.map(x=>(<div className='mt-2 UserTag'>
  //             {x}
  //             <button onClick={()=>removeFriend(x)} style={{width: 30, height: 30, marginInline: 5}}>
  //                 -
  //               </button>
  //             <br/>
  //             </div>))
  //           }
  //       </div>
  //       <div className='mt-5 mb-5' style={{width: "80%", height: 1, marginInline: "auto", backgroundColor: "#ffffff44", float: "center"}}/>
  //       <div className='mt-3'>
  //       <p className='Auth-form-title'>Requests</p>
  //       <br/>
  //       {
  //             myAccount["friendRequests"]?.map(x=>(<div className='mt-2 UserTag'>
  //             {x}
  //             <button onClick={()=>addFriend(x)} style={{width: 30, height: 30, marginInline: 5}}>
  //                 +
  //               </button>
  //             <button onClick={()=>removeFriendRequest(x)} style={{width: 30, height: 30, marginInline: 5}}>
  //                 -
  //               </button>
  //             <br/>
  //             </div>))
  //           }
  //       </div>
  //   </div>
  // )

   

  const [selectedTab, setSelectedTab] = useState(0)

  const tabItems=[
    {
      icon: <Person style={{width: 25, height: 25, fill: "white"}}/>,
      title: "Friends",
      card: (
      <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", paddingBottom: 15, }}>
             <div className='mt-3'>
          <p className='Auth-form-title'>Friends</p>
        {
              myAccount["friends"]?.map(x=>
                {
                  let avatar = undefined
                  return (
              // <div className='mt-2 UserTag'>
              // {x}
              // <button onClick={()=>removeFriend(x)} style={{width: 30, height: 30, marginInline: 5}}>
              //     -
              //   </button>
              // <br/>
              // </div>
              <div style={{ width: 400, fontSize: 12, color: "white", display: "flex", flexDirection:"row" }}>
                <div className="UserTag mb-3 mt-3" onClick={()=>{ alert("profile card (modal?)")}} style={{height:40, width: "75%", flex: "75%", boxShadow: ""}}>
                    <div id="icon"><img src={avatar === undefined ? silhouette : avatar} style={{width: 25, height: 25, borderRadius: 15}}/></div> <div id="title" style={{flex: "80%"}}>{x}</div>
                </div>
                <div className="UserTag mb-3 mt-3" onClick={()=>removeFriend(x)} style={{height:40, width: "20%", flex: "20%", marginLeft: "5%", backgroundColor: "#603030", border:"", borderColor:"#0C1A2A33", boxShadow: ""}}>
                    <Trash style={{width: 17, height: 17, fill: "#ccc"}}/>
                </div>
              </div>
              )})
            }
        </div>
      </div>)
    },
    {
      icon: <Person style={{width: 25, height: 25, fill: "white"}}/>,
      title: "Requests",
      card: (
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", paddingBottom: 15, }}>
               <div className='mt-3'>
            <p className='Auth-form-title'>Requests</p>
          {
                myAccount["friendRequests"]?.map(x=>
                  {
                    let avatar = undefined
                    return (
                // <div className='mt-2 UserTag'>
                // {x}
                // <button onClick={()=>removeFriend(x)} style={{width: 30, height: 30, marginInline: 5}}>
                //     -
                //   </button>
                // <br/>
                // </div>
                <div style={{ width: 400, fontSize: 12, color: "white", display: "flex", flexDirection:"row" }}>
                  <div className="UserTag mb-3 mt-3" onClick={()=>{ alert("profile card (modal?)")}} style={{height:40, width: "75%", flex: "75%", boxShadow: ""}}>
                      <div id="icon"><img src={avatar === undefined ? silhouette : avatar} style={{width: 25, height: 25, borderRadius: 15}}/></div> <div id="title" style={{flex: "80%"}}>{x}</div>
                  </div>
                  <div className="UserTag mb-3 mt-3" onClick={()=>addFriend(x)} style={{height:40, width: "20%", flex: "20%", marginLeft: "5%", backgroundColor: "#306030", border:"", borderColor:"#0C1A2A33", boxShadow: ""}}>
                      <Check style={{width: 17, height: 17, fill: "#ccc"}}/>
                  </div>
                <div className="UserTag mb-3 mt-3" onClick={()=>removeFriendRequest(x)} style={{height:40, width: "20%", flex: "20%", marginLeft: "5%", backgroundColor: "#603030", border:"", borderColor:"#0C1A2A33", boxShadow: ""}}>
                    <Trash style={{width: 17, height: 17, fill: "#ccc"}}/>
                </div>
                </div>
                )})
              }
          </div>
        </div>)
    },
    // {
    //   icon: <Person style={{width: 25, height: 25, fill: "white"}}/>,
    //   title: "Suggestions",
    //   card: (
    //   <>
    //     Suggestions  
    //   </>)
    // },
    {
      icon: <Person style={{width: 25, height: 25, fill: "white"}}/>,
      title: "Search",
      card: (
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", paddingBottom: 15, }}>
               <div className='mt-3'>
            <p className='Auth-form-title'>Search</p>
            <input
                style={{width: "max-content", height: "max-content"}}
                type="text"
                placeholder="Search for user"
                value={searchText}
                required
                onChange={(e)=>{setSearch(e.target.value);}}
                onKeyDown={(e)=> {if(e.keyCode == 13) search()}}
              />
              <button onClick={()=>search()} style={{width: "max-content", height: "max-content"}}>
                Search
              </button>
              <br/>
              <br/>
          {
                result.map(x=>
                  {
                    let avatar = undefined
                    return (
                // <div className='mt-2 UserTag'>
                // {x}
                // <button onClick={()=>removeFriend(x)} style={{width: 30, height: 30, marginInline: 5}}>
                //     -
                //   </button>
                // <br/>
                // </div>
                <div style={{ width: 400, fontSize: 12, color: "white", display: "flex", flexDirection:"row" }}>
                  <div className="UserTag mb-3 mt-3" onClick={()=>{ alert("profile card (modal?)")}} style={{height:40, width: "75%", flex: "75%", boxShadow: ""}}>
                      <div id="icon"><img src={avatar === undefined ? silhouette : avatar} style={{width: 25, height: 25, borderRadius: 15}}/></div> <div id="title" style={{flex: "80%"}}>{x}</div>
                  </div>

                  {!myAccount["friends"]?.includes(x) && !myAccount["pendingFriendRequests"]?.includes(x) ? 
                    <div className="UserTag mb-3 mt-3" onClick={()=>sendFriendRequest(x)} style={{height:40, width: "20%", flex: "20%", marginLeft: "5%", backgroundColor: "#306030", border:"", borderColor:"#0C1A2A33", boxShadow: ""}}>
                    <Check style={{width: 17, height: 17, fill: "#ccc"}}/>
                </div>
                      :
                    (myAccount["pendingFriendRequests"]?.includes(x) ? <div className="UserTag mb-3 mt-3" onClick={()=>removePendingFriendRequest(x)} style={{height:40, width: "20%", flex: "20%", marginLeft: "5%", backgroundColor: "#603030", border:"", borderColor:"#0C1A2A33", boxShadow: ""}}>
                    <Trash style={{width: 17, height: 17, fill: "#ccc"}}/>
                </div> : <></>)}
                </div>
                )})
              }
          </div>
        </div>)
    },
  ]

  return (
    <div style={{width: "100%",height: "100%", display: "flex", backgroundColor: "#0C0A1A", flexDirection: "row", color:'white'}}>

    <div className="SettingsNav">
        <ul className="SidebarList">
            {
                tabItems.map((val, key) => (
                    <li
                        key={key+1}
                        className="row"
                        style={{
                          // backgroundColor: "#6275a7",
                          // borderRadius: 20,
                          marginBottom: key === tabItems.length-1 ? 0 : 15
                        }}
                        id={selectedTab == key ? "active" : ""}
                        onClick={()=>{
                          setSelectedTab(key)
                            // window.location.pathname = val.link;
                        }}
                    >
                        <div id="icon">{val.icon}</div> <div id="title">{val.title}</div>
                        {/* HELLo */}
                    </li>
                ))
            }
        </ul>
    </div>
    <div className="SettingsCard">
        {
          tabItems[selectedTab].card
        }
    </div>
    </div>
  )
}

export default Friends
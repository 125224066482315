import { initializeApp } from "firebase/app";

import {getFirestore, collection, doc, setDoc, deleteField  , getCountFromServer, updateDoc, onSnapshot, query, where, limit, getDocs, arrayUnion, arrayRemove, increment, serverTimestamp } from "firebase/firestore"; 

import React, { useContext, useState, useEffect } from "react";
import { getAuth, signOut, updateProfile, createUserWithEmailAndPassword, OAuthProvider, signInAnonymously, onAuthStateChanged, signInWithEmailAndPassword, signInWithPopup, GoogleAuthProvider, FacebookAuthProvider, TwitterAuthProvider, signInWithRedirect, sendPasswordResetEmail } from "firebase/auth";
// import { useNavigate } from "react-router-dom";
// import { SiteRoutes } from "../routes";
import { rankBoard } from "../utils/logic";

const firebaseConfig = {
  apiKey: "AIzaSyBgXt_qv5YwwRmWb46m9_PVA7Q5BcCbI5I",
  authDomain: "five-knights.firebaseapp.com",
  projectId: "five-knights",
  storageBucket: "five-knights.appspot.com",
  messagingSenderId: "823074359109",
  appId: "1:823074359109:web:eb6dab9c34ee977fa8202c",
  measurementId: "G-ZQS4ERHZVC"
};

const specialUsernameWasSetString = "${telsa}"

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);

const kFactorSettle = 20
const kInit = 100
const kFinal = 16
const eloSpreadFact = 400


const DataContext = React.createContext();

export function useData() {
  return useContext(DataContext);
}

export function DataProvider({ children }) {
    // const navigate = useNavigate(); 
     
    const [user, setUser] = useState(undefined)
    const [myAccount, setMyAccount] = useState(undefined)
    const [curBoard, setCurBoard] = useState(undefined)
    const [callback, setCallback] = useState()
    const [myMatches, setMyMatches] = useState([])
    const [myHistory, setMyHistory] = useState([])//setPassAndPlayState
    const [curBoardState, setCurBoardState] = useState(undefined)
    const [passAndPlayState, setPassAndPlayState] = useState(undefined)
    const [aiAgent, setAiAgent] = useState("")
    // const [curBoardHistory, setCurBoardHistory] = useState([])

     function updateState(board,turn,boardId,winner)
     {
        const theirId = boardId.split("|")[0] == getMyUsername() ? boardId.split("|")[1] : boardId.split("|")[0]
        
        const docRefOurs = doc(db,"Users", getMyUsername(), "Extra", "match_0");
        const docRefTheirs = doc(db,"Users", theirId, "Extra", "match_0");
        
        let state  = rankBoard(board, turn)
        let matchHistory = myMatches[curBoard]["history"]===undefined ? [state] : [...(myMatches[curBoard]["history"]),state]
        
        const isWhite = myMatches[curBoard]["isWhite"]
        
        let eloInitFieldName = isWhite ? "eloInitWhite" : "eloInitBlack"
        let eloInitFieldValue = myAccount["elo"]

        let kValFieldName = isWhite ? "kWhite" : "kBlack"
        let kValFieldValue = getkFactor(myAccount["matchCount"])

        let eloIncWhite = winner == 0 ? 0 : getEloUpdate(winner===2,  isWhite ? eloInitFieldValue :  myMatches[curBoard]["eloInitWhite"],  isWhite ? myMatches[curBoard]["eloInitBlack"] : eloInitFieldValue,  isWhite ? kValFieldValue : myMatches[curBoard]["kWhite"])
        let eloIncBlack = winner == 0 ? 0 : getEloUpdate(winner===1, !isWhite ? eloInitFieldValue :  myMatches[curBoard]["eloInitBlack"], !isWhite ? myMatches[curBoard]["eloInitWhite"] : eloInitFieldValue, !isWhite ? kValFieldValue : myMatches[curBoard]["kBlack"])

        updateDoc(docRefOurs  , { [`${boardId}.state`]: state, [`${boardId}.turn`]: turn, [`${boardId}.history`]: matchHistory, [`${boardId}.${eloInitFieldName}`]:eloInitFieldValue, [`${boardId}.${kValFieldName}`]:kValFieldValue, [`${boardId}.winner`]:winner, [`${boardId}.eloIncWhite`]:eloIncWhite, [`${boardId}.eloIncBlack`]:eloIncBlack, [`${boardId}.lastMoveTime`]: serverTimestamp()})
        updateDoc(docRefTheirs, { [`${boardId}.state`]: state, [`${boardId}.turn`]: turn, [`${boardId}.history`]: matchHistory, [`${boardId}.${eloInitFieldName}`]:eloInitFieldValue, [`${boardId}.${kValFieldName}`]:kValFieldValue, [`${boardId}.winner`]:winner, [`${boardId}.eloIncWhite`]:eloIncWhite, [`${boardId}.eloIncBlack`]:eloIncBlack, [`${boardId}.lastMoveTime`]: serverTimestamp()})
    }

    const board = [
        [0, 1, 1, 3, 1, 1, 0],
        [0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0],
        [0, 2, 2, 4, 2, 2, 0],
    ]

    async function createMatch(theirId)
    {
        let turn = 1
        let state  = rankBoard(board, turn)

        const username = getMyUsername();

        const boardNum = getNextMatchNumber(username, theirId)

        const boardId = username+"|"+theirId+"|"+boardNum
          
        const docRefOurs = doc(db,"Users", username, "Extra", "match_0");
        const docRefTheirs = doc(db,"Users", theirId, "Extra", "match_0");

        let history = [state]

        await setDoc(docRefOurs, { [boardId]: {state:state, history:history, turn:turn, isWhite: true, mode: 1} }, { merge: true })
        await setDoc(docRefTheirs, { [boardId]: {state:state, history:history, turn:turn, isWhite: false, mode: 1} }, { merge: true })

        setCurBoard(boardId)

        return boardId
    }

    function userObject(username, bio, uid) 
    {
        return {
            uid: uid,
            username: username,
            bio: bio,
            elo: 1200.0,
            lastSeen: Date.now(),
            isOnline: false,
            usernameLower: username.toLowerCase()
        }
    }

    useEffect(() => {
        onAuthStateChanged(auth, (usr) => {
            setUser(usr)
            if(usr !== null) listenToAccount();
            else setMyAccount(null)
        });

    }, []);
    
    // async function firebaseAuth()
    // {
    //     console.log(auth.currentUser)

    //     if(auth.currentUser === null)
    //     {
    //         signInAnonymously(auth)
    //         .then(() => {
    //             const docRef = doc(db,"Users", auth.currentUser.uid);
    //             setDoc(docRef, userObject).then(()=>{listenToAccount()})
    //         })
    //         .catch((error) => {
    //             alert(error.message);
    //         });
    //     }
    //     else
    //     {
    //         signInAnonymously(auth)
    //         .then(() => {
    //             listenToAccount()
    //         })
    //         .catch((error) => {
    //             alert(error.message);
    //         });
    //     }
    // }

    async function login(type, email, password, )
    {
        let result = undefined
        try{
        if(type === "email")
        {
            result = await signInWithEmailAndPassword(auth, email, password)
        }
        else if(type === "google")
        {
            console.log("google")
            const provider = new GoogleAuthProvider();
            result = await signInWithRedirect(auth, provider);
            console.log(result)
        }
        else if(type === "facebook")
        {
            const provider = new FacebookAuthProvider();
            result = await signInWithRedirect(auth, provider);
        }
        else if(type === "x")
        {
            const provider = new TwitterAuthProvider();
            result = await signInWithRedirect(auth, provider);
        }
        else if(type === "apple")
        {
            const provider = new OAuthProvider('apple.com');
            result = await signInWithPopup(auth, provider);
        }}catch(e){
            return e
            // console.log("LOGIN ERRRO", e)
        }
        return undefined
    }

    async function resetPassword(email)
    {
        const result = await sendPasswordResetEmail(auth, email);
        return result
    }

    async function register(email, password)
    {
        try{
            await createUserWithEmailAndPassword(auth, email, password)
        }catch(e)
        {
            return e
        }
        return undefined
    }
    
    async function initializeAccountDoc(username,bio)
    {
        return await updateProfile(auth.currentUser, { displayName: username + specialUsernameWasSetString}).then(() => 
        {
            const docRef = doc(db,"Users", username);
            setDoc(docRef, userObject(username, bio, auth.currentUser.uid)).then(()=>listenToAccount())
        })
    }

    let unsub = undefined, unsub2 = undefined, unsub3 = undefined

    function getMyUsername()
    {
        return auth.currentUser?.displayName?.replace(specialUsernameWasSetString, "") ?? "";
    }

    function listenToAccount(){
        if(unsub) { unsub(); unsub = undefined; }
        if(unsub2) { unsub2(); unsub2 = undefined; }
        if(unsub3) { unsub3(); unsub3 = undefined; }

        const myUsername = getMyUsername();
        
        if(!auth.currentUser.displayName?.includes(specialUsernameWasSetString)) return;

        unsub = onSnapshot(doc(db,"Users", myUsername), (doc) => { if(doc.exists()) setMyAccount(doc.data()) });
        unsub2 = onSnapshot(doc(db, "Users", myUsername, "Extra", "match_0"), (doc) => { setMyMatches(doc.exists() ? doc.data() : []); onMatchDocUpdated(doc.exists() ? doc.data() : []); })
        unsub3 = onSnapshot(doc(db, "Users", myUsername, "Extra", "history_0"), (doc) => { setMyHistory(doc.exists() ? doc.data()["history"] : []) })
    }

    function onMatchDocUpdated(docData){
        
        let userEloChange = 0;   

        const matchDocUpdate = {}
        const username = getMyUsername()

        Object.keys(docData).map(matchId => {
            const match = docData[matchId]
            if((match["winner"] ?? 0) > 0 && match["eloApplied"] === undefined && match["history"]?.length > 2)
            {
                userEloChange += match["isWhite"] ? match["eloIncWhite"] : match["eloIncBlack"]
                matchDocUpdate[`${matchId}.eloApplied`] = true
            }
        });

        if(Object.keys(matchDocUpdate).length > 0)
        {
            const docRefOurs = doc(db,"Users", username, "Extra", "match_0");
            updateDoc(docRefOurs, matchDocUpdate)

            const docRefMyAccount = doc(db,"Users", username);
            updateDoc(docRefMyAccount, { elo: increment(userEloChange), matchCount: increment(Object.keys(matchDocUpdate).length) })
        }
    }

    function getEloUpdate(didWin, myElo, theirElo, myKFactor)
    {
        return myKFactor*((didWin ? 1 : 0)-(1/(1+Math.pow(10,(theirElo-myElo)/eloSpreadFact))))
    }

    function getkFactor(matchCount){
        if(matchCount > kFactorSettle) return 16
        return kInit-matchCount*(kInit-kFinal)/kFactorSettle
    }

    setTimeout(function () {
        requestAnimationFrame(onsnap)
    })

    async function onsnap(){
        const match = myMatches[curBoard]
        if(match && curBoard !== undefined && curBoard !== "" && callback)
        {
            callback(match)
            setCurBoardState(match)
        }
    }

    const logout = () => signOut(auth)

    async function checkUsernameExists(username)
    {
        const q = query(collection(db, "Users"), where("usernameLower", "==", username.toLowerCase()));
        const snapshot = await getCountFromServer(q);
        return snapshot.data().count === 0
    }

    function getNextMatchNumber(myId, friendId)
    {
        let num = 0
        for (const key in myMatches) if (key.split("|")[0] == myId && key.split("|")[1] == friendId) num = Math.max(num, parseInt(key.split("|")[2]) + 1)
        return num
    }

    function deleteMatch(matchId)
    {
        const friendId = matchId.replace(getMyUsername(), "").replace(matchId.split('|')[2], "").replaceAll("|","")
        updateDoc(doc(db,"Users", getMyUsername(), "Extra", "match_0"), { [matchId]: deleteField() })
        updateDoc(doc(db,"Users", friendId, "Extra", "match_0"), { [matchId]: deleteField() })
    }

    function archiveMatch(boardId)
    {

        const username = getMyUsername();

        const docRefPlay = doc(db,"Users", username, "Extra", "match_0");
        const docRefHist = doc(db,"Users", username, "Extra", "history_0");

        if(myMatches[boardId]["history"]!==undefined && myMatches[boardId]["history"].length > 2){
            // TODO: add before and after elo fields for rated matches; add many things
            setDoc(docRefHist, { ["history"]: arrayUnion({boardId:boardId, lastMoveTime: myMatches[boardId]["lastMoveTime"], history:myMatches[boardId]["history"], isWhite: myMatches[boardId]["isWhite"], mode: myMatches[boardId]["mode"], eloInitWhite:myMatches[boardId]["eloInitWhite"], eloInitBlack:myMatches[boardId]["eloInitBlack"], eloIncWhite:myMatches[boardId]["eloIncWhite"], eloIncBlack:myMatches[boardId]["eloIncBlack"]}) } , { merge: true })
        }
        updateDoc(docRefPlay, { [boardId]: deleteField()})
    }

    async function searchWithUsername(username)
    {
        username = username.toLowerCase()
        const citiesRef = collection(db, "Users");
        const q = query(citiesRef, where("usernameLower", ">=", username), where("usernameLower", "<=", username + '\uf8ff'), limit(10));
        const querySnapshot = await getDocs(q);
        return querySnapshot.docs.map(x=>x.data()["username"])
    }

    async function addFriend(username)
    {
        updateDoc(doc(db, "Users", getMyUsername()), {["friends"]: arrayUnion(username), ["friendRequests"]: arrayRemove(username)})
        updateDoc(doc(db, "Users", username), {["friends"]: arrayUnion(getMyUsername()), ["pendingFriendRequests"]: arrayRemove(getMyUsername())})
    }

    async function removeFriend(username)
    {
        updateDoc(doc(db, "Users", getMyUsername()), {["friends"]: arrayRemove(username)})
        updateDoc(doc(db, "Users", username), {["friends"]: arrayRemove(getMyUsername())})
    }

    async function sendFriendRequest(username)
    {
        updateDoc(doc(db, "Users", username), {["friendRequests"]: arrayUnion(getMyUsername())})
        updateDoc(doc(db, "Users", getMyUsername()), {["pendingFriendRequests"]: arrayUnion(username)})
    }

    async function removeFriendRequest(username)
    {
        updateDoc(doc(db, "Users", getMyUsername()), {["friendRequests"]: arrayRemove(username)})
        updateDoc(doc(db, "Users", username), {["pendingFriendRequests"]: arrayRemove(getMyUsername())})
    }

    async function removePendingFriendRequest(username)
    {
        updateDoc(doc(db, "Users", username), {["friendRequests"]: arrayRemove(getMyUsername())})
        updateDoc(doc(db, "Users", getMyUsername()), {["pendingFriendRequests"]: arrayRemove(username)})
    }

    const value = {
        user,
        curBoard,
        myMatches,
        curBoardState,
        db,
        myAccount,
        myHistory,
        passAndPlayState,
        updateState,
        login,
        register,
        logout,
        initializeAccountDoc,
        checkUsernameExists,
        createMatch,
        setCallback,
        setCurBoard,
        getMyUsername,
        setCurBoardState,
        // deleteMatch,
        archiveMatch,
        resetPassword,
        searchWithUsername,
        addFriend,
        removeFriend,
        sendFriendRequest,
        removeFriendRequest,
        setPassAndPlayState,
        removePendingFriendRequest, 
        aiAgent, setAiAgent
    }

    return (
    <DataContext.Provider value={value}>
        {children}
    </DataContext.Provider>
    );
}